import styled from 'styled-components'

export const CardItemsContainerWrapper = styled.div<{}>`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export const CardItemsUl = styled.ul<{}>`
	display: flex;
	gap: 16px;
	@media screen and (max-width: 1024px) {
		flex-direction: column;
	}
	&:last-child {
		@media screen and (max-width: 1024px) {
			margin-bottom: 16px;
		}
	}
`
