import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const LanguageSwitcherLink = styled(NavLink)`
	color: var(--white);
	text-decoration: none;
	margin-bottom: 0.5rem;
	font-size: 1rem;

	&:hover {
		color: var(--blue);
		transition: 0.3s ease-out;
	}
`
