import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { AppRoute } from 'const'
import { useNavigate } from 'react-router-dom'
import {
	ItemContainer,
	// ItemH5,
	ItemImg,
	// ItemInfo,
	ItemLink,
	ItemPicWrap,
} from './CardItemElements'

interface Props {
	id: number
	thumbnail: string
	titleEs: string
	titleEn: string
}

const CardItem: React.FC<Props> = ({ id, titleEs, titleEn, thumbnail }) => {
	const intl = useIntl()
	const navigate = useNavigate()
	const [title, setTitle] = useState('')

	function localizeRouteKey(path: string) {
		return `/${intl.locale}${intl.formatMessage({ id: path })}`
	}

	useEffect(() => {
		if (intl.locale === 'es') {
			setTitle(titleEs)
		}
		if (intl.locale === 'en') {
			setTitle(titleEn)
		}
	}, [intl, titleEs, titleEn])

	return (
		<>
			<ItemContainer>
				<ItemLink
					onClick={async () => {
						// can navigate to relative paths
						const workDetailRoute = await localizeRouteKey(AppRoute.WorkDetailPage)
						navigate(workDetailRoute.replace(':id', `${id}`))
					}}
				>
					<ItemPicWrap data-category={title}>
						<ItemImg alt='Travel Image' src={thumbnail} />
					</ItemPicWrap>
					{/* <ItemInfo>
					<ItemH5>{type}</ItemH5>
				</ItemInfo> */}
				</ItemLink>
			</ItemContainer>
		</>
	)
}
export default CardItem
