import { useState, useEffect } from 'react'
import CardItemsContainer from 'components/CardItemsContainer'
import { basicItemsProps } from 'types/itemProps'
import Header from 'components/Header'
import { supabase } from 'libs/supabase'
import Loader from 'components/Loader'
import {
	HighlightedContainer,
	CardsContainer,
	CardWrapper,
} from './HighlightedElements'

const Highlight = () => {
	const [hightlightItems, setHightlightItems] = useState<
		basicItemsProps[] | null
	>()
	const getData = async () => {
		if (process.env.NODE_ENV === 'production') {
			try {
				const supabaseData = await supabase
					.from('work_items')
					.select()
					.is('isHighlighted', true)
				// console.log('hightlight: ', supabaseData.data)
				setHightlightItems(supabaseData.data)
			} catch (err) {
				// console.error(err)
			}
		} else {
			const { data } = await import('assets/newContent.json')
			const TmpContent: basicItemsProps[] = data
				.filter(
					(item: basicItemsProps) => item.isHighlighted && item.highlightedPosition
				)
				.sort((a, b) => {
					if (a.highlightedPosition && b.highlightedPosition) {
						if (a.highlightedPosition < b.highlightedPosition) {
							return -1
						}
						if (a.highlightedPosition > b.highlightedPosition) {
							return 1
						}
					}
					return 0
				})
				.map((item: basicItemsProps) => ({
					id: item.id,
					titleEs: item.titleEs,
					titleEn: item.titleEn,
					thumbnail: item.thumbnail,
					isHighlighted: item.isHighlighted,
					highlightedPosition: item.highlightedPosition,
					isMainBackground: item.isMainBackground,
				}))
			setHightlightItems(TmpContent)
		}
	}

	useEffect(() => {
		getData()
	}, [])

	return (
		<HighlightedContainer>
			<Header
				title='home.highlightTitle'
				subtitle='home.highlightSubtitle'
				subtitleLink='AboutPage'
			/>
			<CardsContainer>
				<CardWrapper>
					{hightlightItems ? (
						<CardItemsContainer data={hightlightItems} />
					) : (
						<Loader />
					)}
				</CardWrapper>
			</CardsContainer>
		</HighlightedContainer>
	)
}

export default Highlight
