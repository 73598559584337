import React, { useEffect } from 'react'
import Error from 'components/ErrorSection'

interface Props {
	setIsFixedColor: (arg: boolean) => void
	setColorNav: (arg: string) => void
	setIsBlackTextFixed: (arg: boolean) => void
}

const ErrorPage: React.FC<Props> = props => {
	const { setIsFixedColor, setColorNav, setIsBlackTextFixed } = props

	useEffect(() => {
		setIsFixedColor(true)
		setColorNav('white')
		setIsBlackTextFixed(false)
	}, [setIsFixedColor, setColorNav, setIsBlackTextFixed])

	return (
		<>
			<Error />
		</>
	)
}

export default ErrorPage
