import styled from 'styled-components'

export const WorksContainer = styled.div<{}>`
	background: var(--white);
	padding-top: 110px;
	padding-bottom: 4rem;
`

export const WorksWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 4rem 0 4rem;

	@media screen and (max-width: 768px) {
		padding: 0;
	}
`

export const CardsContainer = styled.div<{}>`
	max-width: 1300px;
	width: 100%;
`
