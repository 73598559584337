import Blocks, { DataProp } from 'editorjs-blocks-react-renderer'
import './BlockStyles.css'

interface BlockContentStyledProps {
	data: DataProp
}

const BlockContentStyled = (props: BlockContentStyledProps) => {
	const { data } = props
	return (
		<>
			<Blocks
				data={data}
				config={{
					delimiter: {
						className: 'font font-size-2 border border-2 w-16 mx-auto mt-4',
					},
					header: {
						className: 'font font-size-2 font-bold mt-4',
					},
					list: {
						className: 'font font-size-2 list-inside mt-4',
					},
					paragraph: {
						className: 'font font-size-2 text-base text-opacity-75 mt-4',
						actionsClassNames: {
							alignment: 'text-left', // This is a substitution placeholder: left or center.
						},
					},
					quote: {
						className: 'font font-size-2 py-3 px-5 italic font-serif mt-4',
					},
					table: {
						className: 'font font-size-2 table-auto mt-4',
					},
				}}
			/>
		</>
	)
}

export default BlockContentStyled
