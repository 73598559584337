import React, { useEffect } from 'react'
import { Login } from '../../components/Auth'

interface Props {
	setDisplayNav: (arg: boolean) => void
}

const LoginPage: React.FC<Props> = props => {
	const { setDisplayNav } = props

	useEffect(() => {
		setDisplayNav(false)
	}, [setDisplayNav])

	return (
		<>
			<Login />
		</>
	)
}

export default LoginPage
