import { useState, useEffect } from 'react'
import { useTransition, animated } from 'react-spring'
import { FormattedMessage } from 'react-intl'
import { supabase } from 'libs/supabase'
import {
	MainContainer,
	MainBg,
	MediaBg,
	MainContent,
	MainH1,
	MainStrong,
	SpaceContainer,
} from './MainElements'

const Main = () => {
	const [images, setImages] = useState<string[] | null>(null)
	const [position, setPosition] = useState(0)
	const secondsToChange = 10

	const transitions = useTransition(position, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		expires: false,
		config: { tension: 220, friction: 120 },
	})

	useEffect(() => {
		const interval = setInterval(() => {
			if (images && images.length > 1) {
				setPosition(index => (index + 1) % images.length)
			}
		}, secondsToChange * 1000)
		return () => {
			clearInterval(interval)
		}
	}, [images])

	const getData = async () => {
		if (process.env.NODE_ENV === 'production') {
			try {
				const supabaseData: any = await supabase
					.from('work_items')
					.select('thumbnail')
					.is('isInMainBackground', true)
				const sortData = await supabaseData.data.sort(
					(a: any, b: any) => a + b && 0.5 - Math.random()
				)
				const auxData = sortData.map(
					(item: { thumbnail: string }) => item.thumbnail
				)
				setImages(auxData)
			} catch (err) {
				// console.error(err)
			}
		} else {
			const { data } = await import('assets/newContent.json')
			const content = data
				.filter((item: any) => item.isMainBackground)
				.map((item: any) => item.thumbnail)

			const sortContent = content.sort((a, b) => a + b && 0.5 - Math.random())
			setImages(sortContent)
		}
	}

	useEffect(() => {
		getData()
	}, [])

	return (
		<SpaceContainer id='home'>
			<MainContainer>
				<MainBg>
					{images &&
						transitions((style, index) => (
							<MediaBg
								as={animated.div}
								src={images ? images[index] : ''}
								style={{ ...style }}
							/>
						))}
				</MainBg>
				<MainContent>
					<MainH1>
						<FormattedMessage id='home.title1' />{' '}
						<MainStrong id='title2'>
							<FormattedMessage id='home.title2' />
						</MainStrong>
					</MainH1>
				</MainContent>
			</MainContainer>
		</SpaceContainer>
	)
}

export default Main
