import styled from 'styled-components'
import NavLink from 'components/NavLinks'

export const FooterContainer = styled.footer<{}>`
	background-color: var(--blue-dark);
	box-shadow: 0 50vh 0 50vh var(--blue-dark);
`

export const FooterWrap = styled.div<{}>`
	padding: 24px 0 48px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 1300px;
	margin: 0 auto;
`

export const FooterLinksContainer = styled.div<{}>`
	display: flex;
	justify-content: center;
`

export const FooterLinksWrapper = styled.div<{}>`
	display: flex;

	@media screen and (max-width: 820px) {
		flex-direction: column;
	}
`

export const FooterLinksItems = styled.div<{}>`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 24px;
	text-align: center;
	width: 160px;
	box-sizing: border-box;
	color: var(--white);
	@media screen and (max-width: 420px) {
		margin: 0;
		padding: 10px;
		width: 100%;
	}
`

export const FooterLinkTitle = styled.h1<{}>`
	font-size: 1.2rem;
	margin-bottom: 16px;
`

export const FooterNavigationLink = styled(NavLink)`
	color: var(--white);
	text-decoration: none;
	margin-bottom: 0.5rem;
	font-size: 1rem;

	&:hover {
		color: var(--blue);
		transition: 0.3s ease-out;
	}
`
export const FooterLink = styled.a`
	color: var(--white);
	text-decoration: none;
	margin-bottom: 0.5rem;
	font-size: 1rem;

	&:hover {
		color: var(--blue);
		transition: 0.3s ease-out;
	}
`

export const SocialMedia = styled.section<{}>`
	width: 100%;
`

export const SocialMediaWrap = styled.div<{}>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;

	@media screen and (max-width: 820px) {
	}
`

export const SocialLogo = styled(NavLink)`
	color: var(--white);
	justify-self: start;
	cursor: pointer;
	text-decoration: none;
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 24px;
	svg {
		font-size: 1.5rem;
		width: 48px;
		fill: var(--white);
	}
`

export const WebsiteRights = styled.small<{}>`
	color: var(--white);
`

export const SocialIcons = styled.div<{}>`
	margin-bottom: 24px;
`

export const SocialIconLink = styled.a<{}>`
	padding: 0 48px;
	color: var(--white);
	font-size: 24px;

	@media screen and (max-width: 820px) {
		text-align: center;
	}
`
