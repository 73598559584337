import { BiError } from 'react-icons/bi'
import { FormattedMessage } from 'react-intl'
import {
	Button,
	Content,
	ContentWrapper,
	ErrorContainer,
	ErrorText,
	IconWrapper,
} from './ErrorElements'

const Error = () => (
	<ErrorContainer>
		<ContentWrapper>
			<IconWrapper>
				<BiError />
				<span>404</span>
			</IconWrapper>
			<Content>
				<ErrorText>
					<FormattedMessage id='error.title' />
				</ErrorText>
				<Button to='HomePage'>
					<FormattedMessage id='error.button' />
				</Button>
			</Content>
		</ContentWrapper>
	</ErrorContainer>
)

export default Error
