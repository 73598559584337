import CardItem from 'components/CardItem'
import React from 'react'
import { basicItemsProps } from 'types/itemProps'
import {
	CardItemsContainerWrapper,
	CardItemsUl,
} from './CardItemsContainerElements'

interface Props {
	data: basicItemsProps[]
	perColumn?: number
}

const CardItemsContainer: React.FC<Props> = ({ data, perColumn = 3 }) => {
	const orderItems = (items: basicItemsProps[]) => {
		if (items) {
			const itemsLength = items.length
			const reverseItems = JSON.parse(JSON.stringify(items)).reverse()
			let counter = 0
			let slice = 0
			const orderedItems = []

			do {
				itemsLength - perColumn >= 0
					? (orderedItems[counter] = reverseItems.slice(
							slice,
							slice + perColumn
					  )).reverse()
					: (orderedItems[counter] = reverseItems.slice(slice)).reverse()
				counter += 1
				slice += perColumn
			} while (itemsLength >= slice)

			return orderedItems.reverse()
		}
		return []
	}

	return (
		<CardItemsContainerWrapper>
			{data &&
				orderItems(data).map(
					(row: basicItemsProps[]) =>
						row.length > 0 && (
							<CardItemsUl>
								{row.map(item => (
									<CardItem
										titleEs={item.titleEs}
										titleEn={item.titleEn}
										thumbnail={item.thumbnail}
										id={item.id}
									/>
								))}
							</CardItemsUl>
						)
				)}
		</CardItemsContainerWrapper>
	)
}

export default CardItemsContainer
