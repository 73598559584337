import React, { useEffect } from 'react'
import About from 'components/AboutSection'

interface Props {
	setIsFixedColor: (arg: boolean) => void
	setColorNav: (arg: string) => void
	setIsBlackTextFixed: (arg: boolean) => void
}

const AboutPage: React.FC<Props> = props => {
	const { setIsFixedColor, setColorNav, setIsBlackTextFixed } = props

	useEffect(() => {
		setIsFixedColor(false)
		setColorNav('white')
		setIsBlackTextFixed(true)
	}, [setIsFixedColor, setColorNav, setIsBlackTextFixed])

	return (
		<>
			<About />
		</>
	)
}

export default AboutPage
