import NavLink from 'components/NavLinks'
import styled from 'styled-components'

export const ErrorContainer = styled.div<{}>`
	background: var(--white);
	padding-top: 110px;
	padding-bottom: 4rem;
`

export const ContentWrapper = styled.div<{}>`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const IconWrapper = styled.div<{}>`
	color: #cc0303;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	span {
		font-size: 3.5rem;
		font-family: monospace;
	}
	svg {
		font-size: 8rem;
	}
`

export const Content = styled.div<{}>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 1.5rem;
`

export const Button = styled(NavLink)<{}>`
	background: var(--blue-dark);
	padding: 8px 16px;
	color: var(--white);
	text-decoration: none;
	margin-top: 16px;
	border-radius: 8px;
	transition: all 0.2s ease;

	&:hover {
		color: var(--blue);
	}
`

export const ErrorText = styled.div`
	font-size: 1.8rem;
`
