import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa'
import NavLink from 'components/NavLinks'

interface SidebarContainerProps {
	isOpen?: boolean
}

export const SidebarContainer = styled.aside<SidebarContainerProps>`
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	background: var(--white);
	display: grid;
	align-items: center;
	top: 0;
	left: 0;
	transition: 0.3s ease-in-out;
	opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
	top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`

export const CloseIcon = styled(FaTimes)`
	color: var(--black);
`

export const Icon = styled.div`
	position: absolute;
	top: 1.2rem;
	right: 1.5rem;
	background: transparent;
	cursor: pointer;
	outline: none;
`

export const SidebarWrapper = styled.div`
	color: var(--black);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

export const SidebarMenu = styled.ul`
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 12px;
	align-items: center;
	justify-content: center;
`

export const SidebarLink = styled(NavLink)`
	display: flex;
	padding: 12px;
	width: 80%;
	align-items: center;
	justify-content: center;
	font-size: 2rem;
	text-decoration: none;
	list-style: none;
	color: var(--black);
	transition: 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		color: var(--blue);
		transition: 0.2s ease-in-out;
	}
`

export const SideBtnWrap = styled.div`
	display: flex;
	justify-content: center;
`

export const SidebarRoute = styled(NavLink)`
	border-radius: 50px;
	background: #01bf71;
	white-space: nowrap;
	padding: 16px 64px;
	color: #010606;
	font-size: 16px;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		transition: all 0.2s ease-in-out;
		background: var(--white);
		color: #010606;
	}
`
