import { ButtonBack, ButtonNext } from 'pure-react-carousel'
import styled from 'styled-components'

export const CarouselContainer = styled.div<{}>`
	position: relative;
`

export const ButtonsContainer = styled.div<{}>`
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const BackButton = styled(ButtonBack)`
	margin-left: 20px;
	background-color: #dddddd57 !important;
	padding: 1rem;

	&:hover {
		background-color: #ddddddb0 !important;
	}

	&:active {
		background-color: #ddddddd6 !important;
	}
`

export const NextButton = styled(ButtonNext)`
	margin-right: 20px;
	background-color: #dddddd57 !important;
	padding: 1rem;

	&:hover {
		background-color: #ddddddb0 !important;
	}

	&:active {
		background-color: #ddddddd6 !important;
	}
`
