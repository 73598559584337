import React from 'react'
import { IntlProvider } from 'react-intl'
import {
	BrowserRouter as Router,
	useLocation,
	Navigate,
} from 'react-router-dom'
import { AppLanguages } from '../../../const'

import { LanguageStrings } from '../localizations'

function getEnumKeyByEnumValue(myEnum: any, enumValue: string): string {
	const keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue)
	return keys.length > 0 ? keys[0] : ''
}

const existLanguage = (newLang: string) =>
	Object.keys(AppLanguages).some(
		existLang => (AppLanguages as any)[existLang] === newLang
	)
interface Props {
	children?: React.ReactNode
	languages?: any
	appStrings: { [prop: string]: LanguageStrings }
	defaultLanguage?: AppLanguages
}

const IntlProviderComponent: React.FC<Props> = ({
	children,
	appStrings,
	defaultLanguage,
	languages,
}) => {
	const { pathname } = useLocation()
	const regexToMatch = new RegExp('/[a-zA-Z]{2}/', 'gi')
	const Textmatch = pathname.match(regexToMatch)

	const { lang = defaultLanguage || AppLanguages.English } =
		Textmatch && Textmatch!.length > 0
			? { lang: Textmatch![0].replaceAll('/', '') }
			: {}

	if (!pathname.includes(`/${lang}/`) || !existLanguage(lang)) {
		let defaultLang = lang

		const isBrowserLanguageExistInApp = getEnumKeyByEnumValue(
			AppLanguages,
			languages
		)

		if (isBrowserLanguageExistInApp in AppLanguages) {
			defaultLang = languages
		} else if (!existLanguage(lang)) {
			defaultLang = 'en'
		}

		document.documentElement.lang = defaultLang

		return <Navigate to={`/${defaultLang}/`} />
	}

	return (
		<IntlProvider locale={lang} messages={appStrings[lang]}>
			{children}
		</IntlProvider>
	)
}

export const LocalizedRouter: React.FC<Props> = ({
	children,
	appStrings,
	defaultLanguage,
	languages,
}) => (
	<Router>
		<IntlProviderComponent
			appStrings={appStrings}
			defaultLanguage={defaultLanguage}
			languages={languages}
		>
			{children}
		</IntlProviderComponent>
	</Router>
)
