import React, { useEffect } from 'react'
import Works from 'components/WorksSection'

interface Props {
	setIsFixedColor: (arg: boolean) => void
	setColorNav: (arg: string) => void
	setIsBlackTextFixed: (arg: boolean) => void
}

const WorkPage: React.FC<Props> = props => {
	const { setIsFixedColor, setColorNav, setIsBlackTextFixed } = props

	useEffect(() => {
		setIsFixedColor(true)
		setColorNav('white')
		setIsBlackTextFixed(false)
	}, [setIsFixedColor, setColorNav, setIsBlackTextFixed])

	return (
		<>
			<Works />
		</>
	)
}

export default WorkPage
