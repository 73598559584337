import { LanguageStrings } from './base-strings'

export const en: LanguageStrings = {
	/** Routes */
	'routes.home': '/',
	'routes.about': '/about',
	'routes.work': '/works',
	'routes.login': '/login',
	'routes.error': '/error',
	'routes.workDetail': '/work-detail/:id',

	/** Page content */
	'home.title': 'Home',
	'home.title1': 'I`m a',
	'home.title2': 'Graphic Designer',
	'home.highlightTitle': 'Creation and innovation in each project worked on',
	'home.highlightSubtitle': 'About me',

	'about.title': 'About me',
	'about.header': 'About me',
	'about.text':
		'Humberto Antonio Juárez Marcano, Bachelor of Arts in Graphic Design. Graduated from the Universidad de Margarita (2021).\n\nAs an independent artist he has worked with design agencies, which has been able to show his abilities in the animation field, digital marketing, branding and 3D modeling.\n\nAfter working on different projects, he can say, “being a graphic designer is the biggest challenge that I have faced, because you never stop learning and especially innovate.”\n\nCurrently wanting to acquire even more working experiences to increase his portfolio. Besides that, he is looking to know new organizations, businesses and people which can develop abilities by facing new challenges. ',
	'about.bottomLine': 'Let´s build something together!',
	'about.my': 'My',
	'about.download': 'Download PDF',
	'about.CV': 'Curriculum',
	'about.portfolio': 'Portfolio',

	'work.title': 'Portfolio',
	'work.header': 'Selected Projects',

	'login.title': 'Login',

	'footer.pageTitle': 'Pages',
	'footer.socialMediaTitle': 'Social Media',
	'footer.contactTitle': 'Contact',
	'footer.contactEmailMe': 'Email me',
	'footer.contactCallMe': 'Call me',
	'footer.languageTitle': 'Languages',

	'workDetail.title': 'WorkDetail',
	'workDetail.date': 'Date',

	'error.title': 'Something goes wrong.',
	'error.button': 'Back to Home',
}
