import React, { useEffect } from 'react'
import Highlight from '../../components/HighlightedSection'
import Main from '../../components/MainSection'

interface Props {
	setIsFixedColor: (arg: boolean) => void
	setColorNav: (arg: string) => void
	setIsBlackTextFixed: (arg: boolean) => void
}

const HomePage: React.FC<Props> = props => {
	const { setIsFixedColor, setColorNav, setIsBlackTextFixed } = props

	useEffect(() => {
		setIsFixedColor(false)
		setColorNav('white')
		setIsBlackTextFixed(false)
	}, [setIsFixedColor, setColorNav, setIsBlackTextFixed])

	return (
		<>
			<Main />
			<Highlight />
		</>
	)
}

export default HomePage
