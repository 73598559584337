import React from 'react'
import { AppLanguages } from 'const'
import { useLocation, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { appStrings } from 'modules/i18n'
import { LanguageSwitcherLink } from './LanguageSwitcherElements'

export const LanguageSwitcher: React.FC = () => {
	const { pathname } = useLocation()
	const params = useParams()
	const { messages } = useIntl()

	function getMatchingRoute(language: string) {
		/**
		 * Get the key of the route the user is currently on
		 */
		const route = pathname.substring(3) // remove local part '/en' from the pathname /en/contact

		let routeHasParams = false
		let paramNames: string[] = []

		const routeKey =
			Object.keys(messages).find(key => {
				if (messages[key] === route) {
					return messages[key] === route
				}

				const hasParams = /\/[:][\w]+/g.exec(messages[key] as string)

				if (hasParams) {
					const getParamsNames = hasParams.map(param => param.replace('/:', ''))
					let newRoute = messages[key]
					getParamsNames.map(
						param =>
							(newRoute = (newRoute as string).replace(
								`:${param}`,
								`${params[param]}`
							))
					)
					if (newRoute === route) {
						routeHasParams = true
						paramNames = getParamsNames
						return newRoute === route
					}
				}
				return false
			}) ?? 'English'
		/**
		 * Find the matching route for the new language
		 */
		const matchingRoute = (appStrings as any)[language][routeKey]

		let directionRoute = `/${language}${matchingRoute}`

		if (routeHasParams) {
			paramNames.map(param => {
				directionRoute = directionRoute.replace(`:${param}`, `${params[param]}`)
				return null
			})
		}
		/**
		 * Return localized route
		 */
		return directionRoute
	}

	return (
		<>
			{Object.keys(AppLanguages).map(lang => (
				<LanguageSwitcherLink
					// activeClassName={css(link.active)}
					to={getMatchingRoute((AppLanguages as any)[lang])}
				>
					{(AppLanguages as any)[lang]}
				</LanguageSwitcherLink>
			))}
		</>
	)
}
