import styled from 'styled-components'
// import { Link as LinkR } from 'react-router-dom'

export const ItemContainer = styled.li<{}>`
	display: flex;
	flex: 1;
	border-radius: 10px;

	@media screen and (max-width: 768px) {
	}
	@media screen and (max-width: 1024px) and (min-width: 769px) {
		margin-bottom: 2rem;
	}
`

export const ItemLink = styled.a<{}>`
	display: flex;
	flex-flow: column;
	width: 100%;
	box-shadow: 0 6px 20px var(--blue-dark-17);
	-webkit-filter: drop-shadow(0 6px 20px var(--blue-dark-17));
	filter: drop-shadow(0 6px 20px var(--blue-dark-17));
	border-radius: 10px;
	overflow: hidden;
	text-decoration: none;
`
export const ItemPicWrap = styled.figure<{}>`
	position: relative;
	width: 100%;
	padding-top: 67%;
	overflow: hidden;

	&::after {
		content: attr(data-category);
		position: absolute;
		bottom: 0;
		padding: 0 0 12px 12px;
		max-width: 100%;
		width: 100%;
		height: 50%;
		font-size: 18px;
		line-height: 24px;
		font-weight: 700;
		color: var(--white);
		background: linear-gradient(transparent, var(--blue-dark-60));
		box-sizing: border-box;
		pointer-events: none;
		display: flex;
		align-items: flex-end;
	}
`
export const ItemImg = styled.img<{}>`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	width: 100%;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
	object-fit: cover;
	transition: all 0.2s linear;

	&:hover {
		transform: scale(1.1);
	}
`
export const ItemInfo = styled.div<{}>`
	padding: 20px 30px 30px;
`
export const ItemH5 = styled.h5<{}>`
	color: var(--blue-dark);
	font-size: 18px;
	line-height: 24px;
`
