import React, { useEffect, useState } from 'react'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'
import { mediaItemsProps } from 'types/itemProps'
import 'pure-react-carousel/dist/react-carousel.es.css'
import './carousel.css'
import DetailsMedia from 'components/DetailsMedia'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import {
	BackButton,
	ButtonsContainer,
	CarouselContainer,
	NextButton,
} from './CarouselElements'

interface Props {
	media: mediaItemsProps[]
}

export const Carousel: React.FC<Props> = ({ media }) => {
	const [naturalSlideWidth, setNaturalSlideWidth] = useState(1)
	const [naturalSlideHeight, setNaturalSlideHeight] = useState(1)

	const { width } = useWindowDimensions()

	useEffect(() => {
		if (width > 2400) {
			setNaturalSlideWidth(10)
			setNaturalSlideHeight(5)
		} else if (width <= 2400) {
			setNaturalSlideWidth(10)
			setNaturalSlideHeight(7)
		}
	}, [width])

	if (media.length <= 0) return null

	return (
		<CarouselContainer>
			{media.length > 1 ? (
				<CarouselProvider
					naturalSlideWidth={naturalSlideWidth}
					naturalSlideHeight={naturalSlideHeight}
					totalSlides={media.length}
					visibleSlides={1}
					currentSlide={1}
					isPlaying
					infinite
				>
					<Slider>
						{media.map((item, index) => (
							<Slide index={index}>
								<DetailsMedia key={`media-${item.src}`} data={item} carousel />
							</Slide>
						))}
					</Slider>
					<ButtonsContainer>
						<BackButton
							style={{
								backgroundColor: 'transparent',
								borderWidth: 0,
							}}
						>
							<FontAwesomeIcon icon={faChevronLeft} fontSize={18} />
						</BackButton>

						<NextButton
							style={{
								backgroundColor: 'transparent',
								borderWidth: 0,
							}}
						>
							<FontAwesomeIcon icon={faChevronRight} fontSize={18} />
						</NextButton>
					</ButtonsContainer>
				</CarouselProvider>
			) : (
				<DetailsMedia key={`media-${media[0].src}`} data={media[0]} />
			)}
		</CarouselContainer>
	)
}
