import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import NavLink from 'components/NavLinks'
// import { Link as LinkS } from 'react-scroll'

interface NavProps {
	scrollNav: boolean
	fixedColor: boolean
	wichColor?: string
	isBlackTextFixed?: boolean
}

export const Nav = styled.div<NavProps>`
	background-color: ${({ scrollNav, fixedColor, wichColor }) => {
		let newColor = 'var(--white-95)'
		if (wichColor === 'black') {
			newColor = 'var(--black-95)'
		}
		if (!fixedColor) {
			return scrollNav ? newColor : 'transparent'
		}
		return newColor
	}};
	transition: ${({ fixedColor }) => {
		if (!fixedColor) {
			return '0.4s all ease'
		}
		return 'none'
	}};
	height: 80px;
	margin-top: -80px;
	backdrop-filter: 'blur(10px)';
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	position: sticky;
	top: 0;
	z-index: 10;
	padding: 0 4rem;

	@media screen and (max-width: 960px) {
		transition: ${({ fixedColor }) => {
			if (!fixedColor) {
				return '0.8s all ease'
			}
			return 'none'
		}};
	}

	@media screen and (max-width: 768px) {
		padding: 0;
	}
`

export const NavbarContainer = styled.div`
	display: flex;
	justify-content: space-between;
	height: 80px;
	z-index: 1;
	width: 100%;
	margin: 0 16px;
	max-width: 1300px;
`

export const NavLogo = styled(NavLink)<NavProps>`
	justify-self: flex-start;
	width: 66.8px;
	cursor: pointer;
	display: flex;
	background-color: ${({ scrollNav, fixedColor, wichColor }) => {
		let newColor = 'transparent'
		if (wichColor === 'black') {
			newColor = 'var(--white)'
		}
		if (!fixedColor && wichColor === 'white') {
			return scrollNav ? 'transparent' : 'var(--white)'
		}
		return newColor
	}};
	transition: 0.4s all ease;
	align-items: center;
	font-weight: bold;
	text-decoration: none;
	svg {
		font-size: 1.5rem;
		width: 80%;
		height: 80%;
		margin: 7px;
		fill: var(--black);
	}
`
export const NavLogoSVG = styled.img`
	color: var(--white);
`

export const MobileIcon = styled.div<NavProps>`
	display: none;

	@media screen and (max-width: 768px) {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 2.5rem;
		padding: 7px;
		color: ${({ scrollNav, fixedColor, wichColor, isBlackTextFixed }) => {
			if (isBlackTextFixed) {
				return 'var(--black)'
			}
			if (wichColor === 'black') {
				return 'var(--white)'
			}
			if (fixedColor) {
				return 'var(--black)'
			}
			return scrollNav ? 'var(--black)' : 'var(--white)'
		}};
		cursor: pointer;
	}
`

export const NavMenu = styled.ul`
	display: flex;
	align-items: center;
	list-style: none;
	text-align: center;

	@media screen and (max-width: 768px) {
		display: none;
	}
`

export const NavItem = styled.li`
	height: 80px;
`

export const NavLinks = styled(NavLink)<NavProps>`
	color: ${({ scrollNav, fixedColor, wichColor, isBlackTextFixed }) => {
		if (isBlackTextFixed) {
			return 'var(--black)'
		}
		if (wichColor === 'white' && !fixedColor) {
			return scrollNav ? 'var(--black)' : 'var(--white)'
		}
		if (wichColor === 'black' && !fixedColor) {
			return 'var(--white)'
		}
		return 'var(--black)'
	}};
	display: flex;
	font-size: 1.5rem;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
	transition: all 0.2s easy;

	&:hover {
		background: var(--blue-dark);
		color: var(--white);
	}
	&.active {
		font-weight: bold;
		background: var(--blue-dark);
		color: var(--white);
	}
`

export const NavBtn = styled.nav`
	display: flex;
	align-items: center;

	@media screen and (max-width: 768px) {
		display: none;
	}
`

export const NavBtnLink = styled(LinkR)`
	border-radius: 50px;
	background: var(--blue);
	white-space: nowrap;
	padding: 10px 22px;
	color: var(--black);
	font-size: 16px;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		transition: all 0.2s ease-in-out;
		background: var(--white);
		color: var(--black);
	}
`
