import styled from 'styled-components'

export const VoxelContainer = styled.div<{}>`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
`

export const VoxelWrapper = styled.div<{}>`
	/* background-color: red; */
	min-width: 100px;
	width: 100%;
	padding-top: 100%; /* 1:1 Aspect Ratio */
	position: relative; /* If you want text inside of it */
`
