import styled from 'styled-components'

export const AboutContainer = styled.div<{}>`
	background: var(--white);
`

export const AboutWrapper = styled.div``

export const TitlePage = styled.h1<{}>`
	font-size: 5rem;
	color: var(--black);
	text-align: left;
	margin-bottom: 2rem;

	/* padding: 0 32px 0 144px;

	@media screen and (max-width: 1590px) {
		padding: 0 32px 0 72px;
	}
	@media screen and (max-width: 1222px) {
		padding: 0 32px;
	} */

	@media screen and (max-width: 1600px) {
		font-size: 3.6rem;
	}
	@media screen and (max-width: 1078px) {
		text-align: center;
	}
	@media screen and (max-width: 820px) {
		font-size: 2.8rem;
	}
`
export const ContentWrapper = styled.div<{}>`
	width: 100%;
	height: 100%;
	display: flex;

	@media screen and (max-width: 1078px) {
		flex-direction: column;
	}
`

export const TextContainer = styled.div<{}>`
	width: 60%;
	padding-top: 110px;

	@media screen and (max-width: 1078px) {
		width: 100%;
	}
`

export const TextWrapper = styled.div<{}>`
	width: 100%;
	height: 100%;
	padding: 0 32px 0 144px;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 1590px) {
		padding: 0 32px 0 72px;
	}
	@media screen and (max-width: 1222px) {
		padding: 0 32px;
	}
`

export const MainText = styled.p<{}>`
	font-size: 1.6rem;
	color: var(--black);
	white-space: pre-wrap;

	@media screen and (max-width: 1600px) {
		font-size: 1.4rem;
	}
	@media screen and (max-width: 820px) {
		font-size: 1.4rem;
	}
	@media screen and (max-width: 672px) {
		font-size: 1.2rem;
	}
`
export const RightSide = styled.div<{}>`
	width: 40%;
	display: flex;
	flex-direction: column;
	background: #ccc;
	padding-top: 110px;
	@media screen and (max-width: 1078px) {
		width: 100%;
		padding-top: 0;
	}
`
export const HighlightedContent = styled.div<{}>`
	display: flex;
	/* justify-content: flex-end; */
`

export const Highlight = styled.p<{}>`
	padding: 0px 12px;
	background: var(--blue-dark);
	color: var(--white);
	font-size: calc(1.6rem * 1.618);
	font-style: italic;

	@media screen and (max-width: 820px) {
		font-size: calc(1.4rem * 1.618);
	}
	@media screen and (max-width: 672px) {
		font-size: calc(1.2rem * 1.618);
	}
`
export const ImgContainer = styled.div<{}>`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	height: 100%;
`

export const AboutImg = styled.img<{}>`
	width: 100%;
	max-width: 100vw;
	object-fit: cover;

	@media screen and (max-width: 1078px) {
		max-width: 720px;
		max-height: 720px;
	}
`
export const ContentButtons = styled.div<{}>`
	width: 100%;
	margin: 2rem 0;
	display: flex;
	gap: 16px;
	align-items: stretch;
	@media screen and (max-width: 672px) {
		flex-direction: column;
	}
`

export const Button = styled.a<{}>`
	text-decoration: none;
	width: 100%;
	display: flex;
`

export const ButtonDate = styled.div<{}>`
	padding: 6px;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 2rem;
	text-transform: uppercase;
	font-weight: bold;
	background: var(--black);
	color: var(--white);

	@media screen and (max-width: 820px) {
		font-size: 1.2rem;
	}
	@media screen and (max-width: 672px) {
		font-size: 1rem;
	}
`

export const ButtonTitleWrapper = styled.div<{}>`
	width: 100%;
	display: flex;
	flex-direction: column;
`

export const ButtonTitle = styled.p<{}>`
	font-size: 1.7rem;
	height: 100%;
	padding-left: 6px;
	padding-top: 6px;
	padding-bottom: 4px;
	text-transform: uppercase;
	background: var(--blue-dark);
	color: var(--white);

	@media screen and (max-width: 1400px) {
		font-size: 1.5rem;
	}
	@media screen and (max-width: 820px) {
		font-size: 1.3rem;
	}
	@media screen and (max-width: 672px) {
		font-size: 1.1rem;
	}
`

export const ButtonSubtitle = styled.p<{}>`
	width: 100%;
	height: 100%;
	text-transform: uppercase;
	font-size: 1.5rem;
	padding-left: 6px;
	background: var(--gray);
	color: var(--black);
	display: flex;
	align-items: flex-end;
	padding-bottom: 6px;

	@media screen and (max-width: 820px) {
		font-size: 1.1rem;
	}
	@media screen and (max-width: 672px) {
		font-size: 0.9rem;
	}
`
