import { AppRoute } from 'const'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { HeaderContainer, Subtitle, TitleH1 } from './HeaderElements'

interface Props {
	title: string
	subtitle?: string
	subtitleLink?: keyof typeof AppRoute
}

const Header: React.FC<Props> = ({ title, subtitle, subtitleLink }) => {
	const { formatMessage, locale } = useIntl()

	function localizeRouteKey(path: string) {
		return `/${locale}${formatMessage({ id: path })}`
	}
	return (
		<HeaderContainer>
			<TitleH1>
				<FormattedMessage id={title} />
			</TitleH1>
			{subtitle && subtitleLink && (
				<Subtitle key={subtitleLink} to={localizeRouteKey(AppRoute[subtitleLink])}>
					<FormattedMessage id={subtitle} />
				</Subtitle>
			)}
		</HeaderContainer>
	)
}

export default Header
