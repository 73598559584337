/* eslint-disable no-param-reassign */
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

export function loadGLTFModel(
	scene,
	glbPath,
	options = { receiveShadow: true, castShadow: true }
) {
	const { recieveShadow, castShadow } = options
	return new Promise((resolve, reject) => {
		const loader = new GLTFLoader()

		loader.load(
			glbPath,
			gltf => {
				const obj = gltf.scene
				obj.name = 'dog'
				obj.position.y = 0
				obj.position.x = 0
				obj.recieveShadow = recieveShadow
				obj.castShadow = castShadow
				scene.add(obj)

				obj.traverse(child => {
					if (child.isMesh) {
						child.castShadow = castShadow
						child.recieveShadow = recieveShadow
					}
				})

				resolve(obj)
			},
			undefined,
			error => {
				reject(error)
			}
		)
	})
}
