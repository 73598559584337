import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { AppRoute, AppRouteTitles } from 'const'
import { NavLink as NavLinkR } from 'react-router-dom'

interface Props {
	to: keyof typeof AppRoute
	children?: React.ReactNode
	className?: string
}

const NavLink: FC<Props> = ({ to, children, className }) => {
	const { formatMessage, locale } = useIntl()

	function localizeRouteKey(path: string) {
		return `/${locale}${formatMessage({ id: path })}`
	}

	return (
		<NavLinkR key={to} to={localizeRouteKey(AppRoute[to])} className={className}>
			{typeof children !== 'undefined'
				? children
				: formatMessage({ id: AppRouteTitles.get(AppRoute[to]) || '' })}
		</NavLinkR>
	)
}

export default NavLink
