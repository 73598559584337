import React from 'react'
import {
	SidebarContainer,
	Icon,
	CloseIcon,
	SidebarWrapper,
	SidebarMenu,
	SidebarLink,
	// SideBtnWrap,
	// SidebarRoute,
} from './SidebarElements'

interface Props {
	isOpen: boolean
	toggle: Function
}
export const Sidebar: React.FC<Props> = ({ isOpen, toggle }) => (
	<SidebarContainer isOpen={isOpen} onClick={() => toggle()}>
		<Icon onClick={() => toggle()}>
			<CloseIcon size={48} />
		</Icon>
		<SidebarWrapper>
			<SidebarMenu>
				<SidebarLink to='HomePage' />
				<SidebarLink to='AboutPage' />
				<SidebarLink to='WorkPage' />
				{/* <SidebarLink to="contact">
					Contact
				</SidebarLink> */}
			</SidebarMenu>
			{/* <SideBtnWrap>
				<SidebarRoute to="/ModoDios">Sign In</SidebarRoute>
			</SideBtnWrap> */}
		</SidebarWrapper>
	</SidebarContainer>
)
