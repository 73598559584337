const baseStrings = {
	/** Routes */
	'routes.home': '/',
	'routes.about': '/sobre-mi',
	'routes.work': '/trabajos',
	'routes.login': '/login',
	'routes.error': '/error',
	'routes.workDetail': '/detalle-trabajo/:id',

	/** Page content */
	'home.title': 'Inicio',
	'home.title1': 'Soy',
	'home.title2': 'Diseñador Gráfico',
	'home.highlightTitle': 'Creación e innovación en cada proyecto trabajado',
	'home.highlightSubtitle': 'Acerca de mí',

	'about.title': 'Sobre mí',
	'about.header': 'Conoce al Diseñador',
	'about.text':
		'Humberto Antonio Juárez Marcano, Licenciado en Artes mención Diseño Gráfico, es un joven venezolano graduado en la Universidad de Margarita (2021). \n\nComo independiente ha trabajado en agencias de diseño, donde ha tenido la oportunidad de demostrar sus habilidades en el campo de la animación, marketing digital, branding y modelado 3D.\n\nDespués de haber trabajado en diversos proyectos, afirma que “Ser diseñador gráfico es el reto más grande al que me he enfrentado, ya que nunca se puede dejar de aprender y mucho menos de innovar”. \n\nActualmente, sigue en la búsqueda de nuevas experiencias laborales para expandir su portafolio. Además, busca conocer nuevas organizaciones, empresas y personas con las que pueda desarrollar  habilidades al enfrentar nuevos retos. ',
	'about.bottomLine': '¡No dudes en contactarme!',
	'about.download': 'Descargar PDF',
	'about.my': 'Mi',
	'about.CV': 'Currículo',
	'about.portfolio': 'Portafolio',

	'work.title': 'Portafolio',
	'work.header': 'Proyectos Destacados',

	'login.title': 'Iniciar sesión',

	'footer.pageTitle': 'Páginas',
	'footer.socialMediaTitle': 'Redes Sociales',
	'footer.contactTitle': 'Contacto',
	'footer.contactEmailMe': 'Mi correo',
	'footer.contactCallMe': 'Mi Teléfono',
	'footer.languageTitle': 'Idiomas',

	'workDetail.title': 'Articulo',
	'workDetail.date': 'Fecha',

	'error.title': 'Ha ocurrido un error.',
	'error.button': 'Volver a Inicio',
}

export type LanguageStrings = typeof baseStrings
export const es = baseStrings
