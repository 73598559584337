import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const HeaderContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 50px;
`

export const TitleH1 = styled.h1<{}>`
	text-align: center;
`
export const Subtitle = styled(NavLink)`
	text-align: center;
	text-decoration: none;
	color: var(--gray);
	transition: 0.2s all ease;
	margin-top: 12px;

	&:hover {
		color: var(--black);
		text-decoration: underline;
	}
`
