import { LoaderContainer, Spinner, SpinnerWrapper } from './LoaderElements'

const Loader = () => (
	<LoaderContainer>
		<SpinnerWrapper>
			<Spinner />
			<Spinner />
			<Spinner />
		</SpinnerWrapper>
	</LoaderContainer>
)

export default Loader
