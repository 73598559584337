import styled from 'styled-components'
import { Image } from 'pure-react-carousel'

export const DetailsMediaContainer = styled.div<{}>`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	color: var(--white);
`

export const MediaWrapper = styled.div<{ isAlone: boolean }>`
	max-width: ${isAlone => (isAlone ? '1100px' : '100%')};
	width: 100%;
	height: auto;
`
export const DetailMediaImgCarousel = styled(Image)`
	/* width: 100%; */
`

export const DetailMediaImg = styled.img`
	width: 100%;
`
export const DetailDescriptionContainer = styled.div<{}>`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 0;
`

export const DetailDescription = styled.small<{}>`
	color: var(--white);
`
