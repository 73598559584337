import { FormattedMessage } from 'react-intl'
import { LanguageSwitcher } from 'components/LanguageSwitcher'
import { FaLinkedin, FaPatreon, FaYoutube } from 'react-icons/fa'
// import { ReactComponent as Logo } from 'assets/logo2.svg'
import {
	FooterContainer,
	FooterLink,
	FooterLinksContainer,
	FooterLinksItems,
	FooterLinksWrapper,
	FooterLinkTitle,
	FooterNavigationLink,
	FooterWrap,
	SocialIconLink,
	SocialIcons,
	// SocialLogo,
	SocialMedia,
	SocialMediaWrap,
	WebsiteRights,
} from './FooterElements'

export const Footer = () => (
	<FooterContainer>
		<FooterWrap>
			<FooterLinksContainer>
				<FooterLinksWrapper>
					{/* pages */}
					<FooterLinksItems>
						<FooterLinkTitle>
							<FormattedMessage id='footer.pageTitle' />
						</FooterLinkTitle>
						<FooterNavigationLink to='HomePage' />
						<FooterNavigationLink to='AboutPage' />
						<FooterNavigationLink to='WorkPage' />
					</FooterLinksItems>
					{/* contact */}
					<FooterLinksItems>
						<FooterLinkTitle>
							<FormattedMessage id='footer.contactTitle' />
						</FooterLinkTitle>
						<FooterLink href='mailto:hjuarez.design@gmail.com' target='_blank'>
							<FormattedMessage id='footer.contactEmailMe' />
						</FooterLink>
						<FooterLink href='https://wa.me/message/44ZQL5P7XPTLP1' target='_blank'>
							Whatsapp
						</FooterLink>
						<FooterLink href='https://www.fiverr.com/juarez_humberto' target='_blank'>
							Fiverr
						</FooterLink>
					</FooterLinksItems>
				</FooterLinksWrapper>
				<FooterLinksWrapper>
					{/* social media */}
					<FooterLinksItems>
						<FooterLinkTitle>
							<FormattedMessage id='footer.socialMediaTitle' />
						</FooterLinkTitle>
						<FooterLink
							href='https://www.instagram.com/juarez_humberto/'
							target='_blank'
						>
							Instagram
						</FooterLink>
						<FooterLink href='https://twitter.com/Juarez_Humberto' target='_blank'>
							Twitter
						</FooterLink>
						<FooterLink
							href='https://www.facebook.com/JuarezHumberto.DG'
							target='_blank'
						>
							Facebook
						</FooterLink>
					</FooterLinksItems>
					{/* languages */}
					<FooterLinksItems>
						<FooterLinkTitle>
							<FormattedMessage id='footer.languageTitle' />
						</FooterLinkTitle>
						<LanguageSwitcher />
					</FooterLinksItems>
				</FooterLinksWrapper>
			</FooterLinksContainer>
			<SocialMedia>
				<SocialMediaWrap>
					{/* <SocialLogo to='HomePage'>
						<Logo />
					</SocialLogo> */}
					<SocialIcons>
						<SocialIconLink
							href='https://www.youtube.com/channel/UCdPTp9IN8ymkdH2LO4E3xkg'
							target='_blank'
							aria-label='Youtube'
						>
							<FaYoutube />
						</SocialIconLink>
						<SocialIconLink
							href='https://www.linkedin.com/in/juarez-humberto'
							target='_blank'
							aria-label='Linkedin'
						>
							<FaLinkedin />
						</SocialIconLink>
						<SocialIconLink
							href='https://www.patreon.com/Juarez_humberto'
							target='_blank'
							aria-label='Patreon'
						>
							<FaPatreon />
						</SocialIconLink>
					</SocialIcons>
					<WebsiteRights>
						Humberto Juarez &copy; {new Date().getFullYear()} All rights reserved.
					</WebsiteRights>
				</SocialMediaWrap>
			</SocialMedia>
		</FooterWrap>
	</FooterContainer>
)
