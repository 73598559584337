import styled from 'styled-components'
// import { animated } from 'react-spring'

export const SpaceContainer = styled.div<{}>`
	height: 100vh;
	pointer-events: none;
`

export const MainContainer = styled.div<{}>`
	background: #0c0c0c;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 30px;
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;

	:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0.2) 0%,
				rgba(0, 0, 0, 0.6) 100%
			),
			linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
		z-index: 2;
	}
`

export const MainBg = styled.div<{}>`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: var(--blue-dark);
`
interface MediaBgProps {
	src: string
}

export const MediaBg = styled.img<MediaBgProps>`
	position: absolute;
	width: 100vw;
	height: 100vh;
	--o-object-fit: cover;
	object-fit: cover;
	background-position: center;
	background-size: cover;
	background-image: ${({ src }) => `url(${src})`};
`

export const MainContent = styled.div<{}>`
	z-index: 3;
	max-width: 1200px;
	position: absolute;
	padding: 8px 24px;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
`

export const MainH1 = styled.h1<{}>`
	color: var(--white);
	font-size: 72px;
	text-align: center;
	font-weight: 400;

	@media screen and (max-width: 768px) {
		font-size: 48px;
	}

	@media screen and (max-width: 480px) {
		font-size: 40px;
	}
`

export const MainStrong = styled.strong<{}>`
	font-weight: bold;
`
