import { useEffect, useState } from 'react'
import './App.css'
import { Route } from 'react-router-dom'
import { Footer } from 'components/Footer'
import { Sidebar } from 'components/Sidebar'
import { Navbar } from 'components/Navbar'
import { AppRoute } from 'const'
import * as pages from 'pages'
import { LocalizedRouter, LocalizedRoutes, appStrings } from 'modules/i18n'

function App() {
	const [isOpen, setIsOpen] = useState(false)
	const [isBlackTextFixed, setIsBlackTextFixed] = useState(false)
	const [isFixedColor, setIsFixedColor] = useState(false)
	const [displayNav, setDisplayNav] = useState(true)
	const [colorNav, setColorNav] = useState('white')

	const toggle = () => {
		setIsOpen(!isOpen)
	}
	const handleChangeFixedColor = (value: boolean) => {
		setIsFixedColor(value)
	}
	const handleChangeDisplayNav = (value: boolean) => {
		setDisplayNav(value)
	}
	const handleChangeColorNav = (value: string) => {
		setColorNav(value)
	}

	const userLang = navigator.language

	useEffect(() => {
		const appLoading = document.getElementById('loader-bg')
		if (appLoading) {
			appLoading.style.display = 'none'
		}
	}, [])

	return (
		<LocalizedRouter languages={userLang} appStrings={appStrings}>
			{displayNav && (
				<>
					<Sidebar isOpen={isOpen} toggle={toggle} />
					<Navbar
						toggle={toggle}
						fixedColor={isFixedColor}
						color={colorNav}
						isBlackTextFixed={isBlackTextFixed}
					/>
				</>
			)}

			<LocalizedRoutes>
				<Route
					path={AppRoute.HomePage}
					element={
						<>
							<pages.HomePage
								setIsFixedColor={handleChangeFixedColor}
								setColorNav={handleChangeColorNav}
								setIsBlackTextFixed={setIsBlackTextFixed}
							/>
							<Footer />
						</>
					}
				/>
				<Route
					path={AppRoute.AboutPage}
					element={
						<>
							<pages.AboutPage
								setIsFixedColor={handleChangeFixedColor}
								setColorNav={handleChangeColorNav}
								setIsBlackTextFixed={setIsBlackTextFixed}
							/>
							<Footer />
						</>
					}
				/>
				<Route
					path={AppRoute.WorkPage}
					element={
						<>
							<pages.WorkPage
								setIsFixedColor={handleChangeFixedColor}
								setColorNav={handleChangeColorNav}
								setIsBlackTextFixed={setIsBlackTextFixed}
							/>
							<Footer />
						</>
					}
				/>
				<Route
					path={AppRoute.WorkDetailPage}
					element={
						<>
							<pages.WorkDetailPage
								setIsFixedColor={handleChangeFixedColor}
								setColorNav={handleChangeColorNav}
								setIsBlackTextFixed={setIsBlackTextFixed}
							/>
							<Footer />
						</>
					}
				/>
				<Route
					path={AppRoute.LoginPage}
					element={<pages.LoginPage setDisplayNav={handleChangeDisplayNav} />}
				/>
				<Route
					path='*'
					element={
						<>
							<pages.ErrorPage
								setIsFixedColor={handleChangeFixedColor}
								setColorNav={handleChangeColorNav}
								setIsBlackTextFixed={setIsBlackTextFixed}
							/>
							<Footer />
						</>
					}
				/>
			</LocalizedRoutes>
		</LocalizedRouter>
	)
}

export default App
