import { Form, FormButton, Icon, FormContent, FormH1, FormInput, FormLabel, FormWrap, LoginContainer, Text } from "./AuthElements"

export const Login = () => (
	<>
		<LoginContainer>
			<FormWrap>
				<Icon to="/">Logo</Icon>
				<FormContent>
					<Form action="#">
						<FormH1>
							Sign in to your account
						</FormH1>
						<FormLabel htmlFor="for">Email</FormLabel>
						<FormInput type="email" required />
						<FormLabel htmlFor="for">Password</FormLabel>
						<FormInput type="password" required />
						<FormButton type="submit">Continue</FormButton>
						<Text> Forgot password</Text>
					</Form>
				</FormContent>
			</FormWrap>
		</LoginContainer>
	</>
)
