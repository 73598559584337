import React from 'react'
import { Frame, FrameContainer } from './YoutubeFrameElements'

interface Props {
	embedId: string
}
const YoutubeFrame: React.FC<Props> = ({ embedId }) => (
	<FrameContainer>
		<Frame
			width='853'
			height='480'
			src={`https://www.youtube.com/embed/${embedId}`}
			frameBorder='0'
			allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
			allowFullScreen
			title='Embedded youtube'
		/>
	</FrameContainer>
)

export default YoutubeFrame
