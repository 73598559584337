import styled from 'styled-components'

export const HighlightedContainer = styled.div<{}>`
	padding: 4rem;
	background: var(--white);
	z-index: 10;

	@media screen and (max-width: 768px) {
		padding: 0;
		padding-top: 4rem;
	}
`
export const CardsContainer = styled.div<{}>`
	display: flex;
	flex-flow: column;
	align-items: center;
	max-width: 1300px;
	width: 100%;
	margin: 0 auto;

	@media screen and (max-width: 820px) {
		margin: 0;
	}
`

export const CardWrapper = styled.div<{}>`
	position: relative;
	margin: 0 0;
	width: 100%;
`
