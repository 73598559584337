import VoxelElement from 'components/VoxelElement'
import YoutubeFrame from 'components/YoutubeFrame'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { mediaItemsProps } from 'types/itemProps'
import {
	DetailDescription,
	DetailDescriptionContainer,
	DetailMediaImg,
	DetailMediaImgCarousel,
	DetailsMediaContainer,
	MediaWrapper,
} from './DetailsMediaElements'

interface Props {
	data: mediaItemsProps
	isAlone?: boolean
	carousel?: boolean
}

const DetailsMedia: React.FC<Props> = ({
	data,
	isAlone = false,
	carousel = false,
}) => {
	const intl = useIntl()
	const [info, setInfo] = useState<string | null>(null)

	// select lang
	useEffect(() => {
		if (intl.locale === 'es') {
			setInfo(data.titleEs)
		}
		if (intl.locale === 'en') {
			setInfo(data.titleEn)
		}
	}, [intl, data])

	return (
		<DetailsMediaContainer>
			<MediaWrapper isAlone={isAlone}>
				{data.type === 'image' && (
					<>
						{carousel ? (
							<DetailMediaImgCarousel
								alt={info ?? ''}
								hasMasterSpinner
								src={data.src}
							/>
						) : (
							<DetailMediaImg alt={info ?? ''} src={data.src} />
						)}
					</>
				)}
				{(data.type === 'gltf' || data.type === '3d') && (
					<VoxelElement src={data.src} />
				)}
				{data.type === 'youtube' && <YoutubeFrame embedId={data.src} />}
			</MediaWrapper>
			<DetailDescriptionContainer>
				<DetailDescription>{info}</DetailDescription>
			</DetailDescriptionContainer>
		</DetailsMediaContainer>
	)
}

export default DetailsMedia
