import { FormattedMessage } from 'react-intl'
import image from 'assets/me.png'
import { useEffect, useState } from 'react'
import { supabase } from 'libs/supabase'
import {
	AboutContainer,
	AboutImg,
	AboutWrapper,
	Button,
	ButtonDate,
	ButtonSubtitle,
	ButtonTitle,
	ButtonTitleWrapper,
	ContentButtons,
	ContentWrapper,
	// Highlight,
	ImgContainer,
	MainText,
	TextContainer,
	TextWrapper,
	TitlePage,
	RightSide,
	// HighlightedContent,
} from './AboutElements'

interface itemsInsideProps {
	id: number
	type: string
	link: string
	// eslint-disable-next-line camelcase
	created_at: string
	isActive: boolean
}

interface itemsProps {
	cv: itemsInsideProps
	portfolio: itemsInsideProps
}

const About = () => {
	const [items, setItems] = useState<itemsProps | null>(null)

	const getData = async () => {
		try {
			const supabaseData = await supabase
				.from('other_files')
				.select('*')
				.is('isActive', true)

			const cv: itemsInsideProps = await supabaseData.data?.find(
				item => item.type === 'cv'
			)
			const portfolio: itemsInsideProps = await supabaseData.data?.find(
				item => item.type === 'portfolio'
			)
			setItems({ cv, portfolio })
		} catch (err) {
			// console.error(err)
		}
	}
	useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
			getData()
		} else {
			const auxData: itemsProps = {
				cv: {
					created_at: '2021-12',
					link: '/',
					type: 'cv',
					isActive: true,
					id: 1,
				},
				portfolio: {
					created_at: '2021-12',
					link: '/',
					type: 'portfolio',
					isActive: true,
					id: 2,
				},
			}
			setItems(auxData)
		}
	}, [])
	return (
		<AboutContainer>
			<AboutWrapper>
				<ContentWrapper>
					{/* text */}
					<TextContainer>
						<TextWrapper>
							<TitlePage>
								<FormattedMessage id='about.header' />
							</TitlePage>
							<MainText>
								<FormattedMessage id='about.text' />
							</MainText>
							{items && (
								<ContentButtons>
									<Button
										href={items.cv.link}
										download='Humberto Juarez - CV'
										target='_blank'
									>
										<ButtonDate>
											{items.cv.created_at.slice(5, 7)} / {items.cv.created_at.slice(0, 4)}
										</ButtonDate>
										<ButtonTitleWrapper>
											<ButtonTitle>
												<FormattedMessage id='about.my' />{' '}
												<strong>
													<FormattedMessage id='about.CV' />
												</strong>
											</ButtonTitle>
											<ButtonSubtitle>
												<FormattedMessage id='about.download' />
											</ButtonSubtitle>
										</ButtonTitleWrapper>
									</Button>
									<Button
										href={items.portfolio.link}
										download='Humberto Juarez - Portfolio'
										target='_blank'
									>
										<ButtonDate>
											{items.portfolio.created_at.slice(5, 7)} /{' '}
											{items.portfolio.created_at.slice(0, 4)}
										</ButtonDate>
										<ButtonTitleWrapper>
											<ButtonTitle>
												<FormattedMessage id='about.my' />{' '}
												<strong>
													<FormattedMessage id='about.portfolio' />
												</strong>
											</ButtonTitle>
											<ButtonSubtitle>
												<FormattedMessage id='about.download' />
											</ButtonSubtitle>
										</ButtonTitleWrapper>
									</Button>
								</ContentButtons>
							)}
						</TextWrapper>
					</TextContainer>
					{/* image */}
					<RightSide>
						{/* <HighlightedContent>
							<Highlight><FormattedMessage id='about.bottomLine' /></Highlight>
						</HighlightedContent> */}
						<ImgContainer>
							<AboutImg alt='It`s me' src={image} />
						</ImgContainer>
					</RightSide>
				</ContentWrapper>
			</AboutWrapper>
		</AboutContainer>
	)
}

export default About
