import React, { useState, useEffect } from 'react'
import { FaBars } from 'react-icons/fa'
import { ReactComponent as Logo } from 'assets/logo2.svg'
import {
	Nav,
	NavbarContainer,
	NavLogo,
	MobileIcon,
	NavMenu,
	NavItem,
	NavLinks,
	// NavBtn,
	// NavBtnLink,
} from './NavbarElements'

interface Props {
	// isOpen: boolean,
	toggle: Function
	fixedColor?: boolean
	color?: string
	isBlackTextFixed?: boolean
}

export const Navbar: React.FC<Props> = ({
	toggle,
	fixedColor = false,
	color = 'white',
	isBlackTextFixed = false,
}) => {
	const [scrollNav, setScrollNav] = useState(false)

	const changeNav = () => {
		if (window.scrollY >= 80) {
			setScrollNav(true)
		} else {
			setScrollNav(false)
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', changeNav)
	})

	return (
		<>
			<Nav scrollNav={scrollNav} fixedColor={fixedColor} wichColor={color}>
				<NavbarContainer>
					<NavLogo
						scrollNav={scrollNav}
						fixedColor={fixedColor}
						wichColor={color}
						to='HomePage'
					>
						<Logo />
					</NavLogo>
					<MobileIcon
						scrollNav={scrollNav}
						fixedColor={fixedColor}
						wichColor={color}
						isBlackTextFixed={isBlackTextFixed}
						onClick={() => toggle()}
					>
						<FaBars />
					</MobileIcon>
					<NavMenu>
						<NavItem>
							<NavLinks
								scrollNav={scrollNav}
								fixedColor={fixedColor}
								wichColor={color}
								isBlackTextFixed={isBlackTextFixed}
								to='AboutPage'
							/>
						</NavItem>
						<NavItem>
							<NavLinks
								scrollNav={scrollNav}
								fixedColor={fixedColor}
								wichColor={color}
								isBlackTextFixed={isBlackTextFixed}
								to='WorkPage'
							/>
						</NavItem>
					</NavMenu>
				</NavbarContainer>
			</Nav>
		</>
	)
}
