import { useEffect, useState } from 'react'
import { basicItemsProps } from 'types/itemProps'
import CardItemsContainer from 'components/CardItemsContainer'
import { supabase } from 'libs/supabase'
import Loader from 'components/Loader'
import { CardsContainer, WorksContainer, WorksWrapper } from './WorksElements'

const Works = () => {
	const [items, setItems] = useState<basicItemsProps[] | null>()

	const getData = async () => {
		if (process.env.NODE_ENV === 'production') {
			try {
				const supabaseData: any = await supabase.from('work_items').select('*')

				setItems(supabaseData.data)
			} catch (err) {
				// console.error(err)
			}
		} else {
			const { data } = await import('assets/newContent.json')

			const TmpContent: basicItemsProps[] = data
				.map((item: basicItemsProps) => ({
					id: item.id,
					titleEs: item.titleEs,
					titleEn: item.titleEn,
					thumbnail: item.thumbnail,
					isHighlighted: item.isHighlighted,
					highlightedPosition: item.highlightedPosition,
					isMainBackground: item.isMainBackground,
				}))
				.reverse()
			setItems(TmpContent)
		}
	}

	useEffect(() => {
		getData()
	}, [])

	return (
		<WorksContainer>
			<WorksWrapper>
				<CardsContainer>
					{items ? <CardItemsContainer perColumn={2} data={items} /> : <Loader />}
				</CardsContainer>
			</WorksWrapper>
		</WorksContainer>
	)
}

export default Works
