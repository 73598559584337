import styled from 'styled-components'

export const WorkDetailsContainer = styled.div<{}>`
	background: var(--black);
	z-index: -8;
`

export const WorkDetailsWrapper = styled.div<{}>`
	height: 60vh;
	pointer-events: none;
`

export const WorkDetailsHeader = styled.div<{}>`
	background: var(--black);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60vh;
	width: 100%;
	position: relative;
	top: 0;
	left: 0;
	z-index: 5;

	:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0.2) 0%,
				rgba(0, 0, 0, 0.6) 100%
			),
			linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
		z-index: 2;
	}
`

export const WorkDetailsBg = styled.div<{}>`
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: var(--black);
`

interface MediaBgProps {
	src: string
}

export const MediaBg = styled.img<MediaBgProps>`
	position: absolute;
	width: 100%;
	height: 100%;
	filter: blur(4px);
	--o-object-fit: cover;
	object-fit: cover;
	background-position: center;
	background-size: cover;
	background-image: ${({ src }) => `url(${src})`};
`

export const WorkDetailsTitle = styled.div<{}>`
	z-index: 3;
	max-width: 1300px;
	padding: 0 16px;
	position: absolute;
	margin: 0 24px;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	justify-content: flex-end;
	align-items: flex-start;
`

export const WorkDetailsH1 = styled.h1<{}>`
	color: var(--white);
	font-size: calc(2.4rem * 1.618);
	text-align: left;
	font-weight: 600;
	margin-bottom: 24px;

	@media screen and (max-width: 768px) {
		font-size: calc(1.6rem * 1.618);
		padding: 0 16px;
	}

	@media screen and (max-width: 480px) {
		font-size: calc(1.2rem * 1.618);
	}
`

export const DetailsContainer = styled.div<{}>`
	width: 100%;
	color: var(--white);
	background: var(--black);
	padding-bottom: 4rem;
`

export const DetailsSlider = styled.div<{}>`
	position: relative;
	margin-top: 24px;
`
export const DetailsSliderWrapper = styled.div<{}>`
	/* display: flex; */
	/* width: 100%; */
	/* justify-content: center; */

	/* & > .details-media {
		min-width: 100%;
	} */
`
export const DetailsWrapper = styled.div<{}>`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const DetailsContent = styled.div<{}>`
	display: flex;
	width: 100%;
	max-width: 1300px;
	margin: 0 16px;

	@media screen and (max-width: 720px) {
		flex-direction: column;
	}
`

export const AsideDetails = styled.div<{}>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 16px 16px 0 16px;
	width: 30%;

	@media screen and (max-width: 720px) {
		width: 100%;
	}
`

export const DetailsArticle = styled.div`
	margin: 24px 16px 0 0;
	width: 70%;

	@media screen and (max-width: 720px) {
		width: 100%;
	}
`

export const DetailsDate = styled.div<{}>``

export const Date = styled.p<{}>``

export const Content = styled.div`
	max-width: 700px;
`

export const DetailsTools = styled.div<{}>``
export const Tool = styled.div<{}>`
	margin-bottom: 12px;
	font-size: calc(0.6rem * 1.618);
`

export const LoadingContainer = styled.div<{}>`
	padding-top: calc(110px + 4rem);
	padding-bottom: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
`
