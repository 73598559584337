import styled from 'styled-components'

export const LoaderContainer = styled.div<{}>`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const SpinnerWrapper = styled.div<{}>`
	border-radius: 50%;
	max-height: 50px;
	min-height: 50px;
	min-width: 50px;
	position: relative;
	box-sizing: border-box;
	border: 3px solid transparent;

	div {
		transition: all 0.3s ease;
	}
	div:nth-child(-n + 2) {
		width: 100%;
		height: 100%;
		position: absolute;
		border: 3px solid transparent;
		border-left: 5px solid var(--blue);
		border-radius: 50%;
		box-sizing: border-box;
	}
	div:first-child {
		animation: rotate 1s ease infinite;
	}
	div:nth-child(2) {
		animation: rotateOpacity 1s ease infinite 0.1s;
	}
	div:last-child {
		width: 100%;
		height: 100%;
		position: absolute;
		border: 3px solid transparent;
		border-left: 5px solid var(--blue);
		animation: rotateOpacity 1s ease infinite 0.2s;
		border-radius: 50%;
		box-sizing: border-box;
	}

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(1turn);
		}
	}
	@keyframes rotateOpacity {
		0% {
			transform: rotate(0deg);
			opacity: 0.1;
		}
		100% {
			transform: rotate(1turn);
			opacity: 1;
		}
	}
`

export const Spinner = styled.div<{}>`
	height: 50px;
	width: 50px;
`
