import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { itemProps } from 'types/itemProps'
import { FormattedMessage, useIntl } from 'react-intl'
import BlockContentStyled from 'components/BlockContentStyled'
import { supabase } from 'libs/supabase'
import Loader from 'components/Loader'
import { Carousel } from 'components/Carousel'
import {
	AsideDetails,
	Content,
	Date,
	DetailsArticle,
	DetailsContainer,
	DetailsContent,
	DetailsDate,
	DetailsSlider,
	DetailsSliderWrapper,
	DetailsTools,
	DetailsWrapper,
	LoadingContainer,
	MediaBg,
	Tool,
	WorkDetailsBg,
	WorkDetailsContainer,
	WorkDetailsH1,
	WorkDetailsHeader,
	WorkDetailsTitle,
	WorkDetailsWrapper,
} from './WorkDetailsElements'

const Article = () => {
	const [content, setContent] = useState<itemProps>()
	const params = useParams()
	const intl = useIntl()
	const [title, setTitle] = useState('')

	// select lang
	useEffect(() => {
		if (content) {
			if (intl.locale === 'es') {
				setTitle(content.titleEs)
			}
			if (intl.locale === 'en') {
				setTitle(content.titleEn)
			}
		}
	}, [intl, content])

	const getData = useCallback(async () => {
		if (params.id && process.env.NODE_ENV === 'production') {
			try {
				const supabaseData: any = await supabase
					.from('work_items')
					.select('*')
					.eq('id', params.id)
				// console.log(supabaseData.data)
				setContent(supabaseData.data[0])
			} catch (err) {
				// console.error(err)
			}
		} else {
			const { data } = await import('assets/newContent.json')

			const TmpContent: itemProps[] = data.filter(
				(item: itemProps) => `${item.id}` === params.id
			)
			setContent(TmpContent[0])
		}
	}, [params.id])

	useEffect(() => {
		// get array off thumbnail links filtered
		getData()
	}, [params, getData])

	return (
		<>
			<WorkDetailsContainer>
				{content ? (
					<>
						{/* title of work details article */}
						<WorkDetailsWrapper>
							<WorkDetailsHeader>
								<WorkDetailsBg>
									<MediaBg src={content.thumbnail} />
								</WorkDetailsBg>
								<WorkDetailsTitle>
									<WorkDetailsH1>{title}</WorkDetailsH1>
								</WorkDetailsTitle>
							</WorkDetailsHeader>
						</WorkDetailsWrapper>
						{/* work details article content */}
						<DetailsContainer>
							<DetailsWrapper>
								<DetailsContent>
									<DetailsArticle>
										<Content>
											<BlockContentStyled
												data={
													intl.locale === 'es'
														? content.descriptionEs
														: content.descriptionEn
												}
											/>
										</Content>
									</DetailsArticle>
									<AsideDetails>
										<DetailsTools>
											{content.tools.tools.map(item => (
												<Tool>
													<strong>
														{intl.locale === 'es' ? item.titleEs : item.titleEn}
													</strong>
													<p>{item.elements.join(', ')}</p>
												</Tool>
											))}
										</DetailsTools>
										<DetailsDate>
											<Date>
												<strong>
													<FormattedMessage id='workDetail.date' />:
												</strong>{' '}
												{content.date}
											</Date>
										</DetailsDate>
									</AsideDetails>
								</DetailsContent>
							</DetailsWrapper>
							<DetailsSlider>
								<DetailsSliderWrapper>
									<Carousel media={content.medias.medias} />
								</DetailsSliderWrapper>
							</DetailsSlider>
						</DetailsContainer>
					</>
				) : (
					<LoadingContainer>
						<Loader />
					</LoadingContainer>
				)}
			</WorkDetailsContainer>

			{/* other posts */}
			{/* here the other posts */}
		</>
	)
}

export default Article
