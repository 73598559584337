export enum AppRoute {
	HomePage = 'routes.home',
	AboutPage = 'routes.about',
	WorkPage = 'routes.work',
	ErrorPage = 'routes.error',
	LoginPage = 'routes.login',
	WorkDetailPage = 'routes.workDetail',
}

export const AppRouteTitles = new Map([
	[AppRoute.HomePage, 'home.title'],
	[AppRoute.AboutPage, 'about.title'],
	[AppRoute.WorkPage, 'work.title'],
	[AppRoute.ErrorPage, 'error.title'],
	[AppRoute.LoginPage, 'login.title'],
	[AppRoute.WorkDetailPage, 'workDetail.title'],
]);